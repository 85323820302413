<template>
  <span class="edit-portal-opening" v-if="hasPerm('portal.delete_youthhomeserviceopening')">
    <b-modal
      dialog-class="modal-xl"
      id="edit-portal-opening"
      cancel-title="Annuler"
      :ok-disabled="!isValid()"
      @ok.prevent="onOk"
      @hidden="onHidden"
      ok-variant="primary"
      ok-title="Ok"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-calendar"></i> Portail - Modification d'une plage d'ouverture</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="youth-home-group"
            :label="youthHomeLabel"
            label-for="youth-home"
          >
            <b-form-select
              id="youth-home"
              v-model="selectedYouthHome"
              disabled
              >
              <b-form-select-option
                :value="item"
                v-for="item in youthHomes"
                :key="item.id"
              >
                {{ item.name }} - {{ item.schoolYear.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="seance-type-group"
            label="Types de séances"
            label-for="seance-type"
          >
            <b-form-select
              id="seance-type"
              v-model="selectedSeanceType"
              disabled
              >
              <b-form-select-option
                :value="item"
                v-for="item in seanceTypes"
                :key="item.id"
              >
                {{ item.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="period-group"
            label="Période"
            label-for="period"
          >
            <b-form-select
              id="period"
              v-model="selectedPeriod"
              disabled
              >
              <b-form-select-option
                :value="item"
                v-for="item in periods"
                :key="item.id"
              >
                {{ item.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="opening-at-group"
            label="Ouverture"
            label-for="opening-at"
          >
            <datetime-input id="opening-at" v-model="openingAt"></datetime-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="closing-at-group"
            label="Fermeture"
            label-for="closing-at"
          >
            <datetime-input id="closing-at" v-model="closingAt"></datetime-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="filteredOptions.length">
        <b-col>
          <b-form-group
            id="options-group"
            label="Options"
            label-for="options"
          >
            <b-form-select
              id="options"
              v-model="selectedOption"
              >
              <b-form-select-option
                :value="item"
                v-for="item in filteredOptions"
                :key="item.id"
              >
                {{ getOptionLabel(item) }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import DatetimeInput from '@/components/Controls/DatetimeInput'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { BackendApi } from '@/utils/http'
import { compareDates } from '@/utils/sorting'
import { makeOpeningOptions } from '@/types/portal'

export default {
  name: 'edit-portal-opening',
  components: {
    DatetimeInput,
  },
  mixins: [BackendMixin],
  props: {
    opening: Object,
  },
  data() {
    return {
      youthHomes: [],
      selectedYouthHome: null,
      periods: [],
      selectedPeriod: null,
      seanceTypes: [],
      selectedSeanceType: null,
      openingAt: null,
      closingAt: null,
      errorText: '',
      options: [],
      selectedOption: null,
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    filteredOptions() {
      if (this.selectedYouthHome && this.selectedSeanceType) {
        const options = this.options.filter(
          elt => (
            (elt.youthHomeNumber === this.selectedYouthHome.number) &&
            (elt.seanceTypeId === this.selectedSeanceType.id)
          )
        )
        if (options.length) {
          return [makeOpeningOptions()].concat(options)
        }
      }
      return []
    },
  },
  watch: {
    opening: function() { this.init() },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      if (this.opening) {
        this.openingAt = moment(this.opening.openingAt).format('YYYY-MM-DDTHH:mm')
        this.closingAt = moment(this.opening.closingAt).format('YYYY-MM-DDTHH:mm')
        this.selectedYouthHome = this.opening.youthHome
        this.youthHomes = [this.selectedYouthHome]
        this.selectedSeanceType = this.opening.seanceType
        this.seanceTypes = [this.selectedSeanceType]
        this.selectedPeriod = this.opening.period
        this.periods = [this.selectedPeriod]
        if (this.opening.options) {
          const index = this.options.map(elt => elt.id).indexOf(this.opening.options.id)
          if (index >= 0) {
            this.selectedOption = this.options[index]
          }
        }
      }
    },
    isValid() {
      if (this.openingAt === null) {
        return false
      }
      if (this.closingAt === null) {
        return false
      }
      if (this.selectedOption && this.selectedOption.id && !this.selectedOption.isActive) {
        return false
      }
      if (compareDates(this.openingAt, this.closingAt) >= 0) {
        return false
      }
      return this.opening !== null
    },
    getOptionLabel(item) {
      return item.name + ((!item.id || item.isActive) ? '' : ' [INACTIF]')
    },
    onHidden() {
      this.$emit('hidden')
    },
    async onOk() {
      this.errorText = ''
      if (this.opening) {
        try {
          const url = '/api/portal/openings/' + this.opening.id + '/'
          const backendApi = new BackendApi('patch', url)
          let data = {
            opening_at: this.openingAt,
            closing_at: this.closingAt,
            options: (this.selectedOption && this.selectedOption.id) ? this.selectedOption.id : null,
          }
          await backendApi.callApi(data)
          await this.addSuccess('La période d\'ouverture a été modifiée')
          this.$emit('done')
          this.$bvModal.hide('edit-portal-opening')
        } catch (err) {
          this.errorText = this.getErrorText(err)
          await this.addError(this.getErrorText(err))
        }
      }
    },
    async loadOptions() {
      let url = '/portal/api/youth-home-opening-options/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.options = resp.data.map(
          elt => makeOpeningOptions(elt)
        ).filter(
          elt => elt.isActive || (this.opening.options && elt.id === this.opening.options.id)
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
  async mounted() {
    await this.loadOptions()
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
</style>
