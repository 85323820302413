<template>
  <span class="add-portal-opening" v-if="hasPerm('portal.add_youthhomeserviceopening')">
    <b-modal
      dialog-class="modal-xl"
      id="add-portal-opening"
      cancel-title="Annuler"
      :ok-disabled="!isValid()"
      @ok.prevent="onOk"
      @hidden="onHidden"
      ok-variant="primary"
      ok-title="Ok"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-calendar"></i> Portail - Ajout d'une plage d'ouverture</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="youth-home-group"
            :label="youthHomeLabel"
            label-for="youth-home"
          >
            <b-form-select
              id="youth-home"
              v-model="selectedYouthHome"
              required
              >
              <b-form-select-option
                :value="item"
                v-for="item in youthHomes"
                :key="item.id"
              >
                {{ item.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="seance-type-group"
            label="Types de séances"
            label-for="seance-type"
          >
            <b-form-select
              id="seance-type"
              v-model="selectedSeanceType"
              :disabled="selectedYouthHome === null"
              required
              >
              <b-form-select-option
                :value="item"
                v-for="item in filteredSeanceTypes"
                :key="item.id"
              >
                {{ item.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="period-group"
            label="Période"
            label-for="period"
          >
            <b-form-select
              id="period"
              v-model="selectedPeriod"
              required
              :disabled="selectedSeanceType === null"
              >
              <b-form-select-option
                :value="item"
                v-for="item in filteredPeriods"
                :key="item.id"
              >
                {{ item.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="opening-at-group"
            label="Ouverture"
            label-for="opening-at"
          >
            <datetime-input id="opening-at" v-model="openingAt"></datetime-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="closing-at-group"
            label="Fermeture"
            label-for="closing-at"
          >
            <datetime-input id="closing-at" v-model="closingAt"></datetime-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="filteredOptions.length">
        <b-col>
          <b-form-group
            id="options-group"
            label="Options"
            label-for="options"
          >
            <b-form-select
              id="options"
              v-model="selectedOption"
              >
              <b-form-select-option
                :value="item"
                v-for="item in filteredOptions"
                :key="item.id"
              >
                {{ item.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import DatetimeInput from '@/components/Controls/DatetimeInput'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { makeYouthHome, makeSeanceType, makeSeancePeriod } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import { compareDates } from '@/utils/sorting'
import { makeOpeningOptions } from '@/types/portal'

export default {
  name: 'add-portal-opening',
  components: {
    DatetimeInput,
  },
  mixins: [BackendMixin],
  props: {
  },
  data() {
    return {
      youthHomes: [],
      selectedYouthHome: null,
      periods: [],
      selectedPeriod: null,
      seanceTypes: [],
      selectedSeanceType: null,
      openingAt: null,
      closingAt: null,
      options: [],
      selectedOption: null,
      errorText: '',
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    filteredSeanceTypes() {
      if (this.selectedYouthHome) {
        return this.seanceTypes.filter(
          elt => ((elt.youthHomes.length === 0) || (elt.youthHomes.indexOf(this.selectedYouthHome.number) >= 0))
        )
      }
      return []
    },
    filteredPeriods() {
      if (this.selectedYouthHome && this.selectedSeanceType) {
        return this.periods.filter(
          elt => (
            (elt.youthHomes.length === 0) || (elt.youthHomes.indexOf(this.selectedYouthHome.number) >= 0)
          )
        ).filter(
          elt => (
            (elt.seanceTypes.length === 0) || (elt.seanceTypes.indexOf(this.selectedSeanceType.name) >= 0)
          )
        )
      }
      return []
    },
    filteredOptions() {
      if (this.selectedYouthHome && this.selectedSeanceType) {
        const options = this.options.filter(
          elt => (
            (elt.youthHomeNumber === this.selectedYouthHome.number) &&
            (elt.seanceTypeId === this.selectedSeanceType.id)
          )
        )
        if (options.length) {
          return [makeOpeningOptions()].concat(options)
        }
      }
      return []
    },
  },
  watch: {
    filteredSeanceTypes: function(value) {
      if (value.length === 1) {
        this.selectedSeanceType = value[0]
      } else {
        this.selectedSeanceType = null
      }
    },
    filteredPeriods: function(value) {
      if (value.length === 1) {
        this.selectedPeriod = value[0]
      } else {
        this.selectedPeriod = null
      }
    },
    selectedPeriod: function(value) {
      if (value && this.selectedYouthHome) {
        const timeframe = value.getTimeframe(this.selectedYouthHome.schoolYear.id)
        if (timeframe.length === 2) {
          this.openingAt = moment(timeframe[0]).format('YYYY-MM-DDTHH:mm')
          this.closingAt = moment(timeframe[1]).format('YYYY-MM-DDTHH:mm')
        }
      }
    },
    filteredOptions: function() {
      this.selectedOption = null
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    isValid() {
      if (this.selectedYouthHome === null) {
        return false
      }
      if (this.selectedSeanceType === null) {
        return false
      }
      if (this.selectedPeriod === null) {
        return false
      }
      if (this.openingAt === null) {
        return false
      }
      if (this.closingAt === null) {
        return false
      }
      if (compareDates(this.openingAt, this.closingAt) >= 0) {
        return false
      }
      return true
    },
    onHidden() {
      this.$emit('hidden')
    },
    async onOk() {
      this.errorText = ''
      try {
        const url = '/api/portal/openings/'
        const backendApi = new BackendApi('post', url)
        let data = {
          youth_home: this.selectedYouthHome.id,
          seance_type: this.selectedSeanceType.id,
          period: this.selectedPeriod.id,
          opening_at: this.openingAt,
          closing_at: this.closingAt,
          options: (this.selectedOption && this.selectedOption.id) ? this.selectedOption.id : null,
        }
        await backendApi.callApi(data)
        await this.addSuccess('La période d\'ouverture a été ajoutée')
        this.$emit('done')
        this.$bvModal.hide('add-portal-opening')
      } catch (err) {
        this.errorText = this.getErrorText(err)
        await this.addError(this.getErrorText(err))
      }
    },
    async loadYouthHomes() {
      let url = '/api/youth/active-youth-homes/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.youthHomes = resp.data.map(elt => makeYouthHome(elt))
        if (this.youthHomes.length === 1) {
          this.selectedYouthHome = this.youthHomes[0]
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSeanceTypes() {
      let url = '/api/youth/seance-types/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.seanceTypes = resp.data.map(elt => makeSeanceType(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadPeriods() {
      let url = '/api/youth/seance-periods/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.periods = resp.data.map(elt => makeSeancePeriod(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadOptions() {
      let url = '/portal/api/youth-home-opening-options/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.options = resp.data.map(elt => makeOpeningOptions(elt)).filter(elt => elt.isActive)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
  async mounted() {
    await this.loadYouthHomes()
    await this.loadSeanceTypes()
    await this.loadPeriods()
    await this.loadOptions()
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
</style>
