<template>
  <div class="family-portal-openings" v-if="hasPerm('portal.view_youthhomeserviceopening')">
    <page-header
      title="Portail - Périodes d'ouverture et messages aux familles"
      icon="fa fa-calendar"
      :links="getLinks()"
    >
    </page-header>
    <b-row>
      <b-col cols-lg="7"  v-if="hasPerm('portal.view_youthhomeserviceopening')">
        <b-form-group id="'next-group" label-for="next">
          <b-form-checkbox
            id="next"
            v-model="onlyNext"
            name="next"
            :value="true"
            :unchecked-value="false"
          >
            Seulement les périodes à venir
          </b-form-checkbox>
        </b-form-group>
        <loading-gif :loading-name="loadingName"></loading-gif>
        <b-row v-if="openings.length === 0" class="">
          <b-col><h3><i class="empty-text">Aucune période d'inscription</i></h3></b-col>
        </b-row>
        <b-row v-if="openings.length > 0">
          <b-col>
            <table class="table table-striped table-responsive">
              <tr>
                <th>{{ youthHomeLabel }}</th>
                <th>Période</th>
                <th>Ouverture</th>
                <th>Fermeture</th>
              </tr>
              <tr v-for="elt in openings" v-bind:key="elt.id">
                <td>
                  {{ elt.youthHome.name }} - {{ elt.youthHome.schoolYear.name }}
                  {{ elt.seanceType.name }}
                  <div v-if="elt.options">
                    {{ elt.options.name }}
                  </div>
                  <div v-if="elt.service === null" class="info-message">
                    <i class="fa fa-warning"></i> Ce type de séance n'est pas autorisé sur le portail famille.
                  </div>
                  <div v-else class="info-message">
                    <div>{{ elt.service.getDescription() }}</div>
                    <div v-if="elt.service.onlyCodes">Seulement les séances {{ elt.service.onlyCodes }}</div>
                    <div v-if="elt.service.ignoreCodes">Ignore les séances {{ elt.service.ignoreCodes }}</div>
                  </div>
                </td>
                <td>
                  {{ elt.period.name }}
                </td>
                <td>
                  {{ elt.openingAt | dateToString('llll') }}
                </td>
                <td>
                  {{ elt.closingAt | dateToString('llll') }}
                </td>
                <td>
                  <a href @click.prevent="editOpening(elt)"
                     v-if="hasPerm('portal.change_youthhomeserviceopening')"
                  >
                    <i class="fa fa-edit"></i>
                  </a>
                </td>
                <td>
                  <a href @click.prevent="deleteOpening(elt)"
                     v-if="hasPerm('portal.delete_youthhomeserviceopening')"
                  >
                    <i class="fa fa-close"></i>
                  </a>
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-col>
      <b-col v-if="hasPerm('portal.view_usermessage')" cols="4">
        <div class="user-messages-col">
          <div class="user-message"><b>Messages aux familles</b></div>
          <loading-gif :loading-name="loadingMessagesName"></loading-gif>
          <b-row v-if="userMessages.length === 0" class="">
            <b-col><h3><i class="empty-text">Aucun message</i></h3></b-col>
          </b-row>
          <b-row v-if="userMessages.length > 0">
            <b-col>
              <div v-for="elt in userMessages" :key="elt.id" class="user-message">
                <b-row>
                  <b-col cols="10">
                    <b>{{ getYouthHomeName(elt.youthHomeNumber )}}</b>
                  </b-col>
                  <b-col cols="1" v-if="hasPerm('portal.change_usermessage')">
                    <a @click.prevent="editUserMessage(elt)" href v-b-tooltip="'Modifier'">
                      <i class="fa fa-edit"></i>
                    </a>
                  </b-col>
                  <b-col cols="1" v-if="hasPerm('portal.delete_usermessage')">
                    <a @click.prevent="deleteUserMessage(elt)" href v-b-tooltip="'Supprimer'">
                      <i class="fa fa-close"></i>
                    </a>
                  </b-col>
                </b-row>
                <div>
                  <div class="bordered-text" v-html="getText(elt)"></div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
    <add-portal-opening @done="loadOpenings"></add-portal-opening>
    <edit-portal-opening @done="loadOpenings" :opening="selectedOpening"></edit-portal-opening>
    <delete-portal-opening @done="loadOpenings" :opening="deletedOpening"></delete-portal-opening>
    <add-family-message @done="loadUserMessages" :user-message="selectedUserMessage"></add-family-message>
    <confirm-modal
      name="delete-family-message"
      :object="selectedUserMessage"
      title="Suppression d'un message"
      :text="getDeleteMessage()"
      @confirmed="onDeleteMessageConfirmed"
    ></confirm-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import AddPortalOpening from '@/components/Portal/AddPortalOpening'
import DeletePortalOpening from '@/components/Portal/DeletePortalOpening'
import EditPortalOpening from '@/components/Portal/EditPortalOpening'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import { BackendMixin } from '@/mixins/backend'
import { makePortalOpening } from '@/types/portal'
import { makeUserMessage, makeYouthHome } from '@/types/youth'
import { BackendApi } from '@/utils/http'
import AddFamilyMessage from '@/components/Portal/AddFamilyMessage'
import { htmlText } from '@/utils/html'
import store from '@/store'

export default {
  name: 'family-portal-openings',
  mixins: [BackendMixin],
  components: {
    AddFamilyMessage,
    LoadingGif,
    PageHeader,
    AddPortalOpening,
    DeletePortalOpening,
    EditPortalOpening,
    ConfirmModal,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'portal-openings',
      loadingMessagesName: 'portal-messages',
      openings: [],
      selectedOpening: null,
      deletedOpening: null,
      onlyNext: true,
      userMessages: [],
      youthHomes: [],
      selectedUserMessage: null,
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
    onlyNext() {
      this.loadOpenings()
    },
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
  },
  mounted() {
    this.loadAll()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadAll() {
      await this.loadOpenings()
      await this.loadUserMessages()
    },
    async loadYouthHomes() {
      let url = '/api/youth/active-youth-homes/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.youthHomes = resp.data.map(elt => makeYouthHome(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadOpenings() {
      this.startLoading(this.loadingName)
      this.openings = []
      this.selectedOpening = null
      this.deletedOpening = null
      let url = '/api/portal/openings/'
      if (this.onlyNext) {
        url += '?next=1'
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.openings = resp.data.map(elt => makePortalOpening(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadUserMessages() {
      this.startLoading(this.loadingMessagesName)
      await this.loadYouthHomes()
      this.selectedUserMessage = null
      this.userMessages = []
      let url = '/api/portal/user-messages/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.userMessages = resp.data.map(elt => makeUserMessage(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingMessagesName)
    },
    addOpening() {
      this.$bvModal.show('add-portal-opening')
    },
    addUserMessage() {
      this.selectedUserMessage = null
      this.$bvModal.show('add-family-message')
    },
    editUserMessage(userMessage) {
      this.selectedUserMessage = userMessage
      this.$bvModal.show('add-family-message')
    },
    deleteUserMessage(userMessage) {
      this.selectedUserMessage = userMessage
      this.$bvModal.show('bv-confirm-modal:delete-family-message')
    },
    async onDeleteMessageConfirmed(event) {
      this.$bvModal.hide('bv-confirm-modal:delete-family-message')
      if (event.object) {
        try {
          const url = '/api/portal/user-messages/' + event.object.id + '/'
          const backendApi = new BackendApi('delete', url)
          await backendApi.callApi()
          this.selectedUserMessage = null
          await this.addSuccess('Le message a été supprimé')
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        await this.loadUserMessages()
      }
    },
    editOpening(opening) {
      this.selectedOpening = opening
      this.$bvModal.show('edit-portal-opening')
    },
    deleteOpening(opening) {
      this.deletedOpening = opening
      this.$bvModal.show('delete-portal-opening')
    },
    getLinks() {
      const links = []
      if (this.hasPerm('portal.add_youthhomeserviceopening')) {
        links.push(
          {
            id: 1,
            label: 'Nouvelle période',
            callback: this.addOpening,
            icon: 'fa fa-plus',
            cssClass: 'btn-primary',
          }
        )
      }
      if (this.hasPerm('portal.add_usermessage')) {
        links.push(
          {
            id: 2,
            label: 'Nouveau message',
            callback: this.addUserMessage,
            icon: 'fa fa-plus',
            cssClass: 'btn-secondary',
          }
        )
      }
      return links
    },
    getYouthHomeName(youthHomeNumber) {
      if (youthHomeNumber === 0) {
        return 'Tous'
      } else {
        const matching = this.youthHomes.filter(elt => elt.number === youthHomeNumber)
        if (matching.length) {
          return matching[0].baseName()
        }
        return ''
      }
    },
    getText(elt) {
      return htmlText(elt.text)
    },
    getDeleteMessage() {
      if (this.selectedUserMessage) {
        const name = this.getYouthHomeName(this.selectedUserMessage.youthHomeNumber)
        return 'Voulez-vous supprimer le message ' + name + '?'
      } else {
        return 'Voulez-vous supprimer le message?'
      }
    },
  },
}
</script>

<style lang="less">
.table-striped th {
  border: none;
}
.info-message {
  border: solid 1px #ccc;
  background: #e0e0e0;
  border-radius: 2px;
  padding: 2px;
  font-size: 0.7em;
}
.user-message {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #000;
}
.user-message:last-of-type {
  border-bottom: none
}
.user-messages-col {
  border: solid 1px #ccc;
  border-radius: 4px;
  padding: 5px;
}
.bordered-text {
  border: solid 1px #ccc;
  border-radius: 4px;
  padding: 3px;
}
</style>
