<template>
  <span class="delete-portal-opening" v-if="hasPerm('portal.delete_youthhomeserviceopening')">
    <b-modal
      dialog-class="modal-md"
      id="delete-portal-opening"
      cancel-title="Annuler"
      :ok-disabled="!isValid()"
      @ok.prevent="onOk"
      @hidden="onHidden"
      ok-variant="danger"
      ok-title="Supprimer"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-calendar"></i> Portail - Suppression d'une plage d'ouverture</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row v-if="opening">
        <b-col>
          <div class="intro-text">
            Êtes-vous sûr de vouloir supprimer la plage d'ouverture pour les inscriptions?
          </div>
          <table class="small-table">
            <tr>
              <th>{{ youthHomeLabel }}</th>
              <td>{{ opening.youthHome.name }} - {{ opening.youthHome.schoolYear.name }}</td>
            </tr>
            <tr>
              <th>Type de séance</th>
              <td>{{ opening.seanceType.name }}</td>
            </tr>
            <tr>
              <th>Période</th>
              <td>{{ opening.period.name }}</td>
            </tr>
            <tr>
              <th>Ouverture</th>
              <td>{{ opening.openingAt | dateToString('llll') }}</td>
            </tr>
            <tr>
              <th>Fermeture</th>
              <td>{{ opening.closingAt | dateToString('llll') }}</td>
            </tr>
            <tr v-if="opening.options">
              <th>Options</th>
              <td>{{ opening.options.name }}</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import store from '@/store'

export default {
  name: 'edit-portal-opening',
  components: {},
  mixins: [BackendMixin],
  props: {
    opening: Object,
  },
  data() {
    return {
      errorText: '',
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
  },
  watch: {
    opening: function() { },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    isValid() {
      return this.opening !== null
    },
    onHidden() {
      this.$emit('hidden')
    },
    async onOk() {
      if (this.opening) {
        this.errorText = ''
        try {
          const url = '/api/portal/openings/' + this.opening.id + '/'
          const backendApi = new BackendApi('delete', url)
          await backendApi.callApi()
          await this.addSuccess('La période d\'ouverture a été supprimée')
          this.$emit('done')
          this.$bvModal.hide('delete-portal-opening')
        } catch (err) {
          this.errorText = this.getErrorText(err)
          await this.addError(this.getErrorText(err))
        }
      }
    },
  },
  async mounted() {
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
  .intro-text {
    padding: 10px 0;
    margin-bottom: 10px;
    font-weight: bold;
  }
</style>
